<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="salesProspecting"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })" :style="{ color: getAccountNameColor(item) }">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <!---------------FOR RE-ACCREDITATION---------------->
                            <template v-slot:[`item.sp_primary_details.next_reaccreditation_date`]="{item}">
                                {{formatReAccreditationDate(item.sp_primary_details.next_reaccreditation_date)}}
                            </template>
                            <!--------------------------------------------------->
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    v-if="initialStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/initial-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="reAccreditationStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/existing-sales-prospecting/re-accreditation-validation/'+item.id})"
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="finalStatus.includes(item.status)"
                                    text
                                    color="primary"
                                    @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id, query: { viewOnly: true } }) "
                                    >
                                    <v-icon>mdi-eye-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        salesProspecting: [],
        initialStatus: ['pending','Scorecard Failed','Inital Evaluation Committee Approval Pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','existing','Not accepted','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Archived','Evaluation (Hanging)','Initial Evaluation Committee Approval Pending'],
        finalStatus: ['Initial Evaluation Complete','Onboarding Approval Pending','Onboarding Approved','Initial Existing Complete','Existing Complete','Existing (Validation Approval Pending)','Onboarding (Returned)','Validation (Returned)','Onboarding (Hanging)','Merged (IS)','Existing Complete (IS)'],
        reAccreditationStatus: ['Onboarding Approved - Endorsed to Validation Committee','Validation Approved', 'Onboarding Approval Pending','For Re-Accreditation','Onboarding (Returned)','Validation (Returned)'],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Approval Status',
                sortable: true,
                value: 'status'
            },
            {
                text: 'Re-Accreditation Status',
                sortable: true,
                value: 'sp_primary_details.reaccreditation_status'
            },
            {
                text: 'Re-Accreditation Date',
                sortable: true,
                value: 'sp_primary_details.next_reaccreditation_date',
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        greenStatus: ['Initial Evaluation Complete','Onboarding Approved','Initial Existing Complete','Existing Complete','Merged (IS)','Existing Complete (IS)'],
        orangeStatus: ['pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','Onboarding Approval Pending','existing','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Termination Pending','Deletion Pending','Initial Evaluation Committee Approval Pending','Onboarding (Returned)','Validation (Returned)','Existing (Validation Approval Pending)'],
        redStatus: ['Scorecard Failed','Not accepted','Archived','Existing (Temporarily Terminated)','Terminated','Evaluation (Hanging)'],
    }
  },
  computed: {
    getAccountNameColor() {
        return (item) => {
            if (this.greenStatus.includes(item.status)) {
                return 'green'
            } else if (this.orangeStatus.includes(item.status)) {
                return 'orange'
            } else if (this.redStatus.includes(item.status)) {
                return 'red'
            } 
            else {
                return 'black'
            }
        }
    },
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const res = await this.$store.dispatch('salesProspecting/doGetAllListSalesProspecting')
    this.salesProspecting = res.data.result
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
  },
  methods: {
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    formatReAccreditationDate(date) {
    if (!date) return ''; // handle empty date
    return date.split(' ')[0]; // Extracts only the date part
    },
  }
}
</script>

<style scoped>
.initial-status {
  color: blue;
}

.final-status {
  color: green;
}
</style>